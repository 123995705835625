import './App.css';
import { getSessionToken } from "@shopify/app-bridge/utilities";

import {useEffect, useState, React, useCallback} from 'react';
import {gql} from '@apollo/client';
import {useApolloClient} from '@shopify/react-graphql';
import ReactDOM from 'react-dom';
import {Provider, Loading, useAppBridge} from '@shopify/app-bridge-react';
import {AppProvider, Layout, Page, LegacyCard,FormLayout,Scrollable,List, Select, Button} from '@shopify/polaris';
import createApp from "@shopify/app-bridge";


function App() {
  const config = {apiKey: process.env.REACT_APP_API_KEY, host: process.env.REACT_APP_HOST_KEY, shop: process.env.REACT_APP_SHOP_NAME};
  const [themes, setThemes] = useState('');
  const [selected, setSelected] = useState('');
  const [listdata, setListdata] = useState([]);
  const [currentdate, setCurrentdate] = useState([]);
  const [jobstatus, setJobstatus] = useState('');
  const [jobstatubtn, setJobstatusbtn] = useState(false);
  const [savebtntxt, setSavebtntxt] = useState('保存');
  const [savebtnstatus, setSavebtnstatus] = useState(false);
  var option_disp = {'': '選択してください'}
  useEffect(() => {
    const selectfunction = async () => {
      const app = createApp(config);
      const sessionToken = await getSessionToken(app);
      fetch(`${process.env.REACT_APP_API_HOSTNAME}/themes`, 
        {headers: { 'Authorization': `Bearer ${sessionToken}`}})
      .then((response) => response.json())
      .then((data) => {
        let option_set = [{label: '選択してください', value: ''}]
        
        for (let element of data){
          option_set.push({label: element['name'], value: element['id']})
          option_disp[element['id']] = element['name']
        }
        setThemes(option_set)
        //setThemeDisp(option_disp)
      });
      fetch(`${process.env.REACT_APP_API_HOSTNAME}/orderddatas`, 
        {headers: { 'Authorization': `Bearer ${sessionToken}`}})
      .then((response) => response.json())
      .then((data) => {
        setListdata(data['data'])
        setCurrentdate(data["date"])
      });
      fetch(`${process.env.REACT_APP_API_HOSTNAME}/jobstatus`, 
        {headers: { 'Authorization': `Bearer ${sessionToken}`}})
      .then((response) => response.json())
      .then((data) => {
        setJobstatus(data['status'])
      });
    }
    selectfunction()
    // handleSelectChange()
  }, [])

  const handleSelectChange = useCallback(
    (value: string) => {
      setSelected(value)
      document.getElementById("theme-select").nextElementSibling.children[0].innerHTML = option_disp[value]
    },
    [],
  );

  async function clickSaveBtn(){
    if(selected){
      const app = createApp(config);
      const sessionToken = await getSessionToken(app);
      const form = new FormData();
      form.append('id', selected);
      setSavebtntxt('保存中')
      setSavebtnstatus(true)

      const res = await fetch(`${process.env.REACT_APP_API_HOSTNAME}/section/set`, 
          {
            method: 'post',
            headers: { 'Authorization': `Bearer ${sessionToken}`},
            body: form
          }
        )
      setSavebtntxt('保存')
      setSavebtnstatus(false)
      if(res.status == 200){
        alert('セクションを書き出しました。注文データは数分後に更新されます。しばらくお待ちください。');
      }else{
        alert('処理に失敗しました。');
      }
      const res2 = fetch(`${process.env.REACT_APP_API_HOSTNAME}/jobstatus`, 
        {headers: { 'Authorization': `Bearer ${sessionToken}`}})
      .then((response) => response.json())
      .then((data) => {
        setJobstatus(data['status'])
      });
    }else{
      alert('テーマを選択してください。')
    }
  }

  async function checkJobStatus(){
    setJobstatus('');
    setJobstatusbtn(true);
    await new Promise(s => setTimeout(s, 800))
    
    const app = createApp(config);
    const sessionToken = await getSessionToken(app);
    const res2 = fetch(`${process.env.REACT_APP_API_HOSTNAME}/jobstatus`, 
      {headers: { 'Authorization': `Bearer ${sessionToken}`}})
    .then((response) => response.json())
    .then((data) => {
      setJobstatus(data['status']);
      setJobstatusbtn(false);
    });
    fetch(`${process.env.REACT_APP_API_HOSTNAME}/orderddatas`, 
      {headers: { 'Authorization': `Bearer ${sessionToken}`}})
    .then((response) => response.json())
    .then((data) => {
      setListdata(data['data'])
      setCurrentdate(data["date"])
    });
  }

  return (
    <Provider config={config}>
      <AppProvider config={config}>
      <p></p>
      <Page>
        <Layout>
          <Layout.Section>
            <LegacyCard
              title="初期インストール"
              primaryFooterAction={{content: savebtntxt, onAction: clickSaveBtn, disabled: savebtnstatus}}
              sectioned
            >
              <FormLayout>
                <p>Sectionがテーマに追加されます。追加するテーマを選んでください。</p>
                <div style={{width:"50%"}}>
                <Select
                  id="theme-select"
                  options={themes}
                  onChange={handleSelectChange}
                  value={selected}
                />
                </div>
              </FormLayout>
            </LegacyCard>
          </Layout.Section>
          <Layout.Section>
            <LegacyCard title="現在表示している商品情報" sectioned>
              
              <FormLayout>
              <p>状態: <span style={{ color: 'red', 'margin-right': '10px' }}>{jobstatus}</span><Button onClick={checkJobStatus} disabled={jobstatubtn}>更新</Button></p>
              <p>最終取得日：{currentdate}</p>
                <Scrollable shadow style={{height: '200px'}} focusable>
                  <List type="bullet">
                  {
                    listdata.map(cdata => 
                    <li key={cdata.id}>{cdata.name}</li>
                    )
                }
                  </List>
                </Scrollable>
              </FormLayout>
            </LegacyCard>
            
          </Layout.Section>
          <Layout.Section>
            <LegacyCard title="お問い合わせ先" sectioned>
              <FormLayout>
                <p>admin@bottleship.co.jp</p>
              </FormLayout>
            </LegacyCard>
          </Layout.Section>
        </Layout>
        
        
    

      </Page>
      </AppProvider>

    </Provider>
  );
}
export default App;